<template lang="pug">
  .matchings(:class="{ loading }")
    BAlert.create-account-alert(
      :show="true"
      variant="warning"
    ) {{ $t("car_class_matching.change_source_warning") }}
    TopBar(
      :allow-save="allowSave"
      @add-new="handleAddNew"
      @save="updateItems"
      @search="applySearch"
    )
    .table-wrapper
      AppOverlayLoader(:state="loading")
      CarClassTable(
        :car-classes="items"
        :car-classes-added="itemsAdded"
        :sources-car-classes-by-ota-id="sourcesCarClassesByOtaId"
        :selected-sources-car-classes="selectedSourcesCarClasses"
        :update-car-class="setItemByIndex"
        :update-added-car-class="setAddedItemByIndex"
        :delete-car-class="deleteItemByIndex"
        :delete-added-car-class="deleteAddedItemByIndex"
        :add-selected-sources-car-class="addSelectedSourcesCarClass"
        :remove-selected-sources-car-class="removeSelectedSourcesCarClass"
        :invalid-item-ids="invalidItemIds"
        :sources-items-loading="sourcesCarClassesLoading"
        :sorting-data="sorting"
        @sorting="handleChangeSorting"
      )
      AppPagination(
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        :current-page="pagination.current_page"
        @change-pagination-data="handleChangePagination"
      )
</template>

<script>
  // store modules
  import carClassMatchingModule from "@/config/store/matching/car_class"
  import sourcesCarClassesModule from "@/config/store/sources/car_classes"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withMatchingPageMethods from "@/mixins/matching_page/withIndexMethods"

  const carClassMatchingMixin = withStoreModule(carClassMatchingModule, {
    resetState: true,
    name: "carClassMatching",
    readers: {
      items: "items",
      itemsAdded: "itemsAdded",
      itemsDeleted: "itemsDeleted",
      carClassesLoading: "loading",
      pagination: "pagination",
      invalidItemIds: "invalidItemIds",
      gettingOtaData: "gettingOtaData",
      sorting: "sorting"
    },
    actions: {
      fetchItemsAction: "FETCH_ITEMS",
      addItem: "ADD_ITEM",
      updateItems: "UPDATE_ITEMS",
      applySearch: "APPLY_SEARCH"
    },
    mutations: {
      setItemByIndex: "SET_ITEM_BY_INDEX",
      setAddedItemByIndex: "SET_ADDED_ITEM_BY_INDEX",
      deleteItemByIndex: "DELETE_ITEM_BY_INDEX",
      deleteAddedItemByIndex: "DELETE_ADDED_ITEM_BY_INDEX",
      setPagination: "SET_PAGINATION_DATA",
      setInvalidItemsIds: "SET_INVALID_ITEM_IDS",
      resetAddedItems: "RESET_ITEMS_ADDED",
      resetDeletedItems: "RESET_ITEMS_DELETED",
      resetItems: "RESET_ITEMS",
      setSorting: "SET_SORTING"
    },
    getters: ["itemsUpdated"]
  })

  const sourcesCarClassesMixin = withStoreModule(sourcesCarClassesModule, {
    resetState: true,
    name: "sourcesCarClasses",
    readers: {
      sourcesCarClassesLoading: "loading",
      selectedSourcesCarClasses: "selectedItems",
      selectedSourcesCarClassesLoading: "selectedItemsLoading"
    },
    getters: { sourcesCarClassesByOtaId: "groupedByOta" },
    actions: {
      fetchSourcesCarClasses: "FETCH_ITEMS",
      fetchSelectedSourcesCarClasses: "FETCH_SELECTED_ITEMS"
    },
    mutations: {
      addSelectedSourcesCarClass: "ADD_SELECTED_ITEM",
      removeSelectedSourcesCarClass: "REMOVE_SELECTED_ITEM"
    }
  })

  export default {
    computed: {
      loading({ carClassesLoading, selectedSourcesCarClassesLoading, sourcesCarClassesLoading }) {
        return carClassesLoading || selectedSourcesCarClassesLoading || sourcesCarClassesLoading
      }
    },

    components: {
      TopBar: () => import("@/components/layout/TopBar"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      CarClassTable: () => import("./CarClassTable"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [carClassMatchingMixin, sourcesCarClassesMixin, withMatchingPageMethods],

    mounted() {
      Promise.all([this.fetchItems(), this.fetchSelectedSourcesCarClasses(), this.fetchSourcesCarClasses()])
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/matchings.sass"
</style>

<style lang="sass" scoped>
  .matchings
    .table-wrapper
      position: relative
</style>
